jQuery(document).ready(function ($) {
  // on désactive le btn ajout au panier
  $("button[name='add-to-cart']").attr("disabled", true);

  // select2 de la modal
  if ($(".select-produit-modal").length) {
    $(".select-produit-modal").select2({
      placeholder: "Choisir",
      width: "100%",
      allowClear: true,
      language: { noResults: () => "Aucune option trouvée" },
    });
  }

  // Modales

  $(".modal-open").click(function () {
    $("#modal-options").modal({ fadeDuration: 250, fadeDelay: 0.8 });
  });

  $(".modal-contact-open").click(function () {
    $("#modal-contactez-nous").modal({
      fadeDuration: 250,
      fadeDelay: 0.8,
      showClose: false,
    });
  });

  $(".modal-devis").click(function () {
    if ($('#datepicker_start').length && $('#datepicker_start').val() == '') {
      alert('Veuillez sélectionner une date de début.');
      return false;
    } else if ($('#datepicker_end').length && $('#datepicker_end').val() == '') {
      alert('Veuillez sélectionner une date de fin.');
      return false;
    } else if ($('#duree_session').length && !$('#duree_session').val()) {
      alert('Veuillez sélectionner une durée.');
      return false;
    } else if ($('#sessions1').val() == '' && $('#sessions2').val() == '' && $('#sessions3').val() == '' && $('#sessions4').val() == '' 
    && $('#sessions5').val() == '' && $('#sessions6').val() == '' && $('#sessions7').val() == '' && $('#sessions8').val() == '') {
      alert('Veuillez sélectionner une session.');
      return false;
    } else {
      rafraichirPrix();
      $("#modal-devis").modal({
        fadeDuration: 250,
        fadeDelay: 0.8,
        showClose: false,
      });
    }
  });

  $(".modal-devis-archive").click(function () {
    $("#modal-devis-archive").modal({
      fadeDuration: 250,
      fadeDelay: 0.8,
      showClose: false,
    });
  });

  $("#anchor").click(function () {
    var anchor = $(this).attr('data-href');
    var element = $('#' + anchor);
    $('html,body').animate({scrollTop: element.offset().top - 200},'slow');
  }); 

  $(".modal-resa-open").click(function () {
    if ($('#datepicker_start').length && $('#datepicker_start').val() == '') {
      alert('Veuillez sélectionner une date de début.');
      return false;
    } else if ($('#datepicker_end').length && $('#datepicker_end').val() == '') {
      alert('Veuillez sélectionner une date de fin.');
      return false;
    } else if ($('#duree_session').length && !$('#duree_session').val()) {
      alert('Veuillez sélectionner une durée.');
      return false;
    } else if ($('#sessions1').val() == '' && $('#sessions2').val() == '' && $('#sessions3').val() == '' && $('#sessions4').val() == '' 
    && $('#sessions5').val() == '' && $('#sessions6').val() == '' && $('#sessions7').val() == '' && $('#sessions8').val() == '') {
      alert('Veuillez sélectionner une session.');
      return false;
    } else {
      var options = true;
      if (!$('.btn-modal-option').hasClass('invisible')) {
        options = false;
        $('#modal-options select').each(function() {
          if ($(this).val() != '') {
            options = true;
          }
        });
      }
      if (!options) {
        alert('Vous pouvez choisir des options si vous le souhaitez.');
      }
      rafraichirPrix();
      $("#modal-reservation").modal({
        fadeDuration: 250,
        fadeDelay: 0.8,
        // escapeClose: false,
        // clickClose: false,
        showClose: false,
      });
    }
  });

  $("form.cart").change(function () {
    rafraichirPrix();
  });

  $("#valider_options").click(function () {
    rafraichirPrix();
    $(".modal-resa-open").attr("disabled", false);
    $.modal.close();
  });
});

//
const rafraichirPrix = () => {
  $(".modal .options_diverses select").trigger('change');
  if ($("input#ecole_de_langue").val() == '1') {
    $(".resume_resa .tg-0lax").each(function() {
      var text = $(this).text();
      if (text == 'Prix séjour') {
        $(this).text('Prix des cours');
      }
      if (text == 'Prix voyage') {
        $(this).parent().css('display', 'none');
      }
      if (text == 'Taxes aériennes') {
        $(this).parent().css('display', 'none');
      }
    });
  }
  $(".resume_resa .option_session").text($("input#option_session").val());
  $(".resume_resa .option_session_prix").text(
    $("#option_session").attr("data-prix") + " €"
  );
  $("#prix_sejour").val($("#option_session").attr("data-prix"));
  $(".resume_resa .option_ville_prix").text(
    $("#option_ville").attr("data-prix") + " €"
  );
  $("#prix_voyage").val($("#option_ville").attr("data-prix"));
  $(".resume_resa .titre_sejour").text($("input#input_2_71").val());
  /* $(".resume_resa .option_tarif_voyage").text(
    $("input#option_tarif_voyage").val()
  ); */
  var nb = 0;
  let nomsOptionsDiverses = [];
  let prixOptionsDiverses = [];
  var options = $("input#option_options_diverses").val();
  if (options.length > 0) {
    options_explode = options.split('|');
    options_explode.forEach(function(option){
      var text_option = option.split('(+');
      var prix = text_option[1].split('€)');
      var text_option = text_option[0];
      var prix = prix[0];
      if (option.substring(option.length - 8) == 'semaines') {
        text_option = text_option + ' (' + prix + ' x ' + option.substring(option.length - 10) + ')';
        nb_semaine = option.substring(option.length - 10, option.length - 9);
        prix = nb_semaine * prix;
        var text_date = $(".resume_resa .option_session").html();
        if (text_date.indexOf("semaine") >= 0) {
        } else {
          $(".resume_resa .option_session").html($(".resume_resa .option_session").html() + '<br />' + option.substring(option.length - 10));
        }
      }
      if (option.substring(option.length - 7) == 'semaine') {
        text_option = text_option + ' (' + prix + ' x ' + option.substring(option.length - 9) + ')';
        nb_semaine = option.substring(option.length - 9, option.length - 8);
        prix = nb_semaine * prix;
        var text_date = $(".resume_resa .option_session").html();
        if (text_date.indexOf("semaine") >= 0) {
        } else {
          $(".resume_resa .option_session").html($(".resume_resa .option_session").html() + '<br />' + option.substring(option.length - 9));
        }
      }
      nomsOptionsDiverses.push(text_option);
      prixOptionsDiverses.push(prix);
      nb = nb + 1;
    });
  }
  if (nb <= 0) {
      $(".resume_resa .option_options_diverses").parent().css("display", "none");
      $(".resume_resa .titre_sejour").attr("rowspan", 8);
      $(".resume_resa .option_session").attr("rowspan", 8);
      $(".resume_resa .option_ville").attr("rowspan", 8);
      $(".tr_option_diverse").remove();
  } else {
      $(".tr_option_diverse").remove();
      $(".resume_resa .option_options_diverses").parent().css("display", "none");
      var rowspan = 8 + parseInt(nb);
      $(".titre_sejour").attr("rowspan", rowspan);
      $(".option_session").attr("rowspan", rowspan);
      $(".option_ville").attr("rowspan", rowspan);
      var nb = 0;
      nomsOptionsDiverses = nomsOptionsDiverses.reverse();
      prixOptionsDiverses = prixOptionsDiverses.reverse();
      nomsOptionsDiverses.forEach(function(option){
        $(".resume_resa .option_options_diverses").parent().after('<tr class="tr_option_diverse"><td class="tg-0lax">' + option + '</td><td class="tg-lqy6 option_options_diverses_prix">' + prixOptionsDiverses[nb] + ' €</td></tr>');
        nb = nb + 1;
      });
  }

  $(".resume_resa .option_tarif_taxes_aeriennes").text(
    // $("input#option_tarif_taxes_aeriennes").val()
    $("#tarif_taxes_aeriennes_h").val() + " €"
  );

  var semaine = $('#duree_sejour option:selected').attr('data-duree');
  if (semaine > 0) {
    if ($("#supplement_haute_saison_h").val() > 0) {
      var semaine_h =  $("#nb_haute_saison_h").val();
      var montant = $("#supplement_haute_saison_h").val() / semaine_h;
      $(".option_tarif_haute_saison").parent().find('.tg-0lax').html('Supplément haute saison<br />(' + montant + ' x ' + semaine_h + ' semaine(s))');
    } else {
      $(".option_tarif_haute_saison").parent().find('.tg-0lax').html('Supplément haute saison');
    }
  }
  $(".resume_resa .option_tarif_haute_saison").text(
    // $("input#option_tarif_taxes_aeriennes").val()
    $("#supplement_haute_saison_h").val() + " €"
  );

  $(".resume_resa .option_ville").text($("input#option_ville").val());

  let assuranceAnnulation = 1;

  if ($("#assurance_annulation_h").val() == "Oui") {
    // assuranceAnnulation = 1.025;
    assuranceAnnulation =
      1 + $("#assurance_annulation_h").attr("data-taux") / 100;
  }

  if ($("#assurance_annulation_h").val() == "Non") {
    assuranceAnnulation = 1;
    $(".resume_resa .option_assurance_prix").text("0 €");
	$('#assurance_annulation_montant').val(0);
  }

  let total =
    // parseInt($("#prix_sejour").data("prix-sejour") || 0) +
    parseInt($("#tarif_voyage_h").val() || 0) +
    parseInt($("#options_diverses_h").val() || 0) +
    parseInt($("#tarif_taxes_aeriennes_h").val() || 0) +
    parseInt($("#villes").find(":selected").val() || 0) +
    parseInt($("#supplement_haute_saison_h").val() || 0) +
    parseInt($("#option_session").attr("data-prix") || 0);

    // Ajout frais de dossier inscription
    total = total + parseInt($("#frais_dinscription").val());

  const prixAssuranceAnnulation =
    Math.round(total * (assuranceAnnulation - 1) * 100) / 100;

  if ($("#assurance_annulation_h").val() == "Oui") {
    $(".resume_resa .option_assurance_prix").text(
      prixAssuranceAnnulation + " €"
    );
	$('#assurance_annulation_montant').val(prixAssuranceAnnulation);
  }

  total = total * assuranceAnnulation;
  total = Math.round(total * 100) / 100;

  // TESTING options séparées : ///////////////////
  /* if ($("input#option_options_diverses").attr("data-detail-options")) {
    const testOptions = JSON.parse(
      $("input#option_options_diverses").attr("data-detail-options")
    );
    testOptions.map((opt) => {
      console.log(opt);
    });
  } */

  ////////////////////////////////

  $(".resume_resa .prix_a_payer, #prix_sejour").text(total + " €");

  /* $(".resume_resa2, .resume_resa3")
    .empty()
    .append($(".resume_resa").parent().clone()); */
};

window.rafraichirPrix = rafraichirPrix;
