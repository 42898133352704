import moment from "moment";
import * as Pikaday from "pikaday";

import { calculateHauteSaisonPrice } from "./haute-saison";

moment.locale("fr");

jQuery(document).ready(function ($) {
  // Disable autocomplete on form
  $("form.cart, form#gform_2").attr("autocomplete", "off");

  // Section prix plus grande pour séjour à la carte :
  if ($("#duree_sejour").data("carte")) {
    $("div.product").css("grid-template-rows", "380px auto");
  }

  let i18n = {
    previousMonth: "Mois précédent",
    nextMonth: "Mois prochain",
    months: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    weekdays: [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
    ],
    weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  };

  let firstDay = $("#datepicker_start").data("weekstart") || 0;

  let fieldStart = document.getElementById("datepicker_start");
  let fieldEnd = document.getElementById("datepicker_end");

  let dureeSejour = $("#duree_sejour").find(":selected").attr("data-duree");

  $("#duree_sejour").change(function () {
    dureeSejour = $("#duree_sejour").find(":selected").attr("data-duree");
    resetDate();
    $("input#option_session").attr(
      "data-prix",
      $(this).find(":selected").attr("data-prix")
    );
  });

  let today = moment(new Date());
  const dateMini = today.endOf("week");

  let startDate, endDate;

  let updateStartDate =  () => {
    startPicker.setStartRange(startDate);
    endPicker.setStartRange(startDate);
    endPicker.setMinDate(startDate);
    calculateHauteSaisonPrice(startDate, endDate);
  };
  let updateEndDate =  () => {
    startPicker.setEndRange(endDate);
    endPicker.setEndRange(endDate);
  };

  let startPicker = new Pikaday({
    field: fieldStart,
    format: "LL",
    i18n: i18n,
    showDaysInNextAndPreviousMonths: true,
    pickWholeWeek: true,
    minDate: new Date(dateMini.format()),
    firstDay: firstDay,
    disableDayFn: function (theDate) {
      if (theDate.getDay() == firstDay) {
        return false;
      }
      return true;
    },
    onSelect: function (date) {
      // let sundayDate = date.getDate() - date.getDay();
      let sundayDate = moment(date.getDate()).startOf("iweek");
      let sunday = new Date(date.setDate(sundayDate));
      let saturday = new Date(
        moment(date.setDate(sundayDate))
          .add(dureeSejour, "weeks")
          .add(-1, "days")
          .add($("#duree_sejour").attr("data-retour-calendrier"), "days")
      );
      fieldStart.value = moment(sunday).format("DD/MM/YYYY");
      fieldEnd.value = moment(saturday).format("DD/MM/YYYY");
      startDate = sunday;
      endDate = saturday;
      updateStartDate();
      updateEndDate();

      // Mise à jour du prix
      $("input#option_session").val(
        moment(sunday).format("DD/MM/YYYY") +
          " au " +
          moment(saturday).format("DD/MM/YYYY") /* +
          "(+" +
          $("input#option_session").attr("data-prix") +
          " €)" */
      );
      //
      rafraichirPrix();
    },
  });
  let endPicker = new Pikaday({
    field: fieldEnd,
    format: "LL",
    i18n: i18n,
    showDaysInNextAndPreviousMonths: true,
    pickWholeWeek: true,
    minDate: new Date(dateMini.format()),
    // onSelect: function (date) {
    //   var sundayDate = date.getDate() - date.getDay();
    //   // var sunday = new Date(date.setDate(sundayDate));
    //   var saturday = new Date(date.setDate(sundayDate + 6));
    //   fieldEnd.value =
    //     /* sunday.toLocaleDateString() + " - " + */ moment(saturday).format(
    //       "LL"
    //     );
    //   // endDate = this.getDate();
    //   endDate = saturday;
    //   updateEndDate();
    // },
  });

  // Reset date
  const resetDate = () => {
    startPicker.clear();
    endPicker.clear();
    startDate = null;
    endDate = null;
    updateStartDate();
    updateEndDate();
  };
  // Reset quand click sur la poubelle
  $("#trash_date").click(function () {
    resetDate();
  });
});
