jQuery(document).ready(function ($) {
  //Filtres sidebar
  $("form#search-sidebar").on("change", function (e) {
    const selectedFilters = $(
      "form#search-sidebar select option:selected:not(:first-child)"
    );

    let filters = {};

    selectedFilters.each(function (index) {
      const valeur = $(this).val();
      const id = $(this).parent().attr("id");
      filters[id] = valeur;
    });

    $.post(
      ajaxurl,
      {
        action: "filtre-sidebar",
        filters,
      },

      function (response) {
        $(
          ".woocommerce-result-count, .woocommerce-ordering, .woocommerce-pagination"
        ).hide();
        $(".archive_content > div").html(response);
      }
    );

    return false;
  });
});
