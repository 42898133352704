import { Loader } from "@googlemaps/js-api-loader";

jQuery(document).ready(function ($) {
  if ($("#acf-map .marker").length > 0) {
    const loader = new Loader({
      // apiKey: "AIzaSyDRwl07jYbd5FTjKrqHkPCqnz8IHqbdBmQ",
      apiKey: "AIzaSyDvqgfavtOh6GtBvbRMFyVmbwtp6Eplw0g",

      version: "weekly",
    });

    const marker = $("#acf-map .marker");
    const lat = marker.data("lat");
    const lng = marker.data("lng");

    loader.load().then(() => {
      let map = new google.maps.Map(document.getElementById("acf-map"), {
        center: { lat, lng },
        zoom: 8,
      });
    });
  }
});
