import Swiper, {Navigation, Pagination, EffectFade, Autoplay} from "swiper";
import "swiper/css/bundle";

const swiperHomeSejour = new Swiper("section.home-sejours .swiper", {
    modules: [Navigation, Autoplay],
    autoHeight: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 30,
    loop: false,
    watchSlidesProgress: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: true,
    },
    breakpoints: {
        769: {
            slidesPerView: 3
        },
    },
    navigation: {
        nextEl: "section.home-sejours .swiper-button-next",
        prevEl: "section.home-sejours .swiper-button-prev",
    },
});

const swiperHomeActus = new Swiper("section.home-actus .swiper", {
    modules: [Navigation, Autoplay],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 50,
    autoplay: {
        delay: 2500,
        disableOnInteraction: true,
    },
    navigation: {
        nextEl: "section.home-actus .swiper-button-next",
        prevEl: "section.home-actus .swiper-button-prev",
    },
});

const swiperGalerieProduit = new Swiper("section#galerie .swiper", {
    modules: [Navigation, Pagination, EffectFade, Autoplay],
    loop: true,
    slidesPerView: 1,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: "section#galerie .swiper-button-next",
        prevEl: "section#galerie .swiper-button-prev",
    },
    pagination: {
        el: "section#galerie .swiper-pagination",
    },
    effect: "fade",
});
