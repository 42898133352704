/* jQuery(document).ready(function ($) {
  $(".modal-devis").click(function () {
    
  });
}); */

const generateDevis = (entry) => {
  $("#pdf_view").empty().append('<div id="loading_spinner"></div>');

  let detail = {};

  const $resume = $(".resume_resa").first();

  detail["sejour"] = $resume.find(".titre_sejour").text();
  detail["periode"] = $resume.find(".option_session").html();
  detail["ville"] = $resume.find(".option_ville ").text();
  detail["taxe_aerienne"] = $resume
    .find(".option_tarif_taxes_aeriennes")
    .text();
  detail["assurance_annulation"] = $("#assurance_annulation_montant").val();
  detail["haute_saison"] = $("#supplement_haute_saison_h").val();
  detail["options"] = $("#option_options_diverses").val();
  detail["prix_total"] = $resume.find(".prix_a_payer").text();
  detail["prix_sejour"] = $resume.find(".option_session_prix").text();
  detail["prix_voyage"] = $resume.find(".option_ville_prix").text();
  detail["prix_options"] = $resume.find(".option_options_diverses_prix").text();

  console.warn("detail : ", detail);
  $.post(
    ajaxurl,
    {
      action: "generate-pdf",
      detail,
      entry,
    },

    function (response) {
      $("#pdf_view").empty().append(response);
    }
  );

  return false;
};

window.generateDevis = generateDevis;
