jQuery(document).ready(function ($) {
  $("#filter-search button").click(function () {
    const vs = $(".search-VS");
    const sl = $(".search-SL");
    $("#filter-search button").removeClass("active");
    $(this).addClass("active");

    if ($(this).data("filtre") == "tous") {
      vs.fadeIn();
      sl.fadeIn();
    }
    if ($(this).data("filtre") == "SL") {
      vs.fadeOut();
      sl.fadeIn();
    }
    if ($(this).data("filtre") == "VS") {
      sl.fadeOut();
      vs.fadeIn();
    }
  });
});
