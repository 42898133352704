if ($("body.single-product").length > 0) {
  // SELECT2 config
  $(document).ready(function () {
    function formatSelection(state) {
      let optionsText = state.text.split(" / ");

      if (!state.id) {
        return state.text;
      }

      let options = optionsText[0];

      if (optionsText[1].trim() == "Disponible") {
        var $state = $('<span class="dispo_produit">' + options + "</span>");
      } else if (optionsText[1].trim() == "Presque complet") {
        var $state = $(
          '<span class="dispo_produit semi">' + options + "</span>"
        );
      } else {
        var $state = $(
          '<span class="dispo_produit full">' + options + "</span>"
        );
      }

      /* var $state = $('<span class="dispo_produit">' + options + "</span>"); */

      return $state;
    }

    function formatResult(state) {
      let optionsText = state.text.split(" / ");

      if (!state.id) {
        return state.text;
      }

      let options = "";
      optionsText.forEach((element) => {
        options += "<span>" + element + "</span>";
      });

      if (optionsText[1].trim() == "Disponible") {
        var $state = $('<span class="dispo_produit">' + options + "</span>");
      } else if (optionsText[1].trim() == "Presque complet") {
        var $state = $(
          '<span class="dispo_produit semi">' + options + "</span>"
        );
      } else {
        var $state = $(
          '<span class="dispo_produit full">' + options + "</span>"
        );
      }

      /* var $state = $('<span class="dispo_produit">' + options + "</span>"); */

      return $state;
    }
    $(".select-produit").select2({
      templateResult: formatResult,
      templateSelection: formatSelection,
      placeholder: "Choisir session",
      width: "100%",
      // minimumResultsForSearch: Infinity
      language: {
        noResults: () => "Aucune session trouvée pour cette période",
      },
    });
  });

  //

  jQuery(document).ready(function ($) {
    //point rouge pour non dispo_produit (à faire à chaque fois qu'on ouvre le select sinon ne se voit pas ) // WIP
    $("li.select2-results__option--disabled .dispo_produit").addClass("full");
    //cacher les dates tant que pas de choix de durée
    $('.select-produit[id^="sessions"]').next(".select2-container").hide();
    //choix durée sessions :
    $("#duree_session").on("change", function () {
      $('.select-produit[id^="sessions"]').val(null).trigger("change");
      $('.select-produit[id^="sessions"]').next(".select2-container").hide();
      $("#" + $(this).find(":selected").val())
        .next(".select2-container")
        .show();
      // On empeche la possibilité de choisir option et reserver tant que pas de période choisie
      $(".modal-devis").attr("disabled", true);
      $(".modal-open").attr("disabled", true);
      $(".modal-resa-open").attr("disabled", true);
    });

    //choix options produit
    // $(".select-produit#sessions").on("change", function () {
    $('.select-produit[id^="sessions"]').on("change", function () {
      $("input#option_session").val($(this).find(":selected").data("option"));
      $("input#option_session").attr(
        "data-prix",
        $(this).find(":selected").val()
      );
      //on laisse choisir les options
      if ($("input#option_session").val() != "") {
        $(".modal-devis").attr("disabled", false);
        $(".modal-open").attr("disabled", false);
        // On donne aussi la possibilité de réserver sans options:
        $(".modal-resa-open").attr("disabled", false);
        // $('.resume_resa .option_session').text($("input#option_session").val());
      }
    });
    $(".select-produit#villes").on("change", function () {
      $("input#option_ville").val($(this).find(":selected").data("option"));
      $("input#option_ville").attr(
        "data-prix",
        $(this).find(":selected").val()
      );
      // $('.resume_resa .option_ville').text($("input#option_ville").val());

      //
      let tarif = $(this).find(":selected").data("taxeaerienne");
      // console.log(tarif);debugger;

      /* var sommeTarif = tarif.reduce((pv, cv) => parseInt(pv) + parseInt(cv), 0); */
      $("input#tarif_taxes_aeriennes_h").val(tarif);
      /* $("input#option_tarif_taxes_aeriennes").val(
        $(this).find(":selected").text()
      ); */
    });
    /* $(".modal #tarif_voyage").on("change", function () {
      $("input#option_tarif_voyage").val(
        $(this).find(":selected").data("option")
      );
      $("input#tarif_voyage_h").val($(this).find(":selected").val());
    }); */
    /* $(".modal #tarif_taxes_aeriennes").on("change", function () {
      // $("input#option_tarif_taxes_aeriennes").val($(this).find(":selected").data("option"));
      // $("input#tarif_taxes_aeriennes").val($(this).find(":selected").val());
      let tarif = $(this).val();
  
      let sommeTarif = tarif.reduce((pv, cv) => parseInt(pv) + parseInt(cv), 0);
      $("input#tarif_taxes_aeriennes_h").val(sommeTarif);
      $("input#option_tarif_taxes_aeriennes").val(
        $(this).find(":selected").text()
      );
    }); */

    /* $(".modal #options_diverses").on("change", function () { */
    $(".modal .options_diverses select").on("change", function () {
      let tarif = 0;
      $(".modal .options_diverses select")
        .find(":selected")
        .each(function () {
          if ($(this).data("semaine") == "1") {
            tarif +=
              parseInt($(this).val()) *
              parseInt($("#duree_sejour").find(":selected").attr("data-duree"));
            /* console.log(
              $(this).val(),
              $("#duree_sejour").find(":selected").attr("data-duree")
            ); */
          }

          if ($(this).data("semaine") == "0") {
            tarif += parseInt($(this).val());
          }
        });

      let nomsOptionsDiverses = [];
      $(".modal .options_diverses select")
        .find(":selected")
        .each(function () {
          if ($(this).data("semaine") == "1") {
            nomsOptionsDiverses.push(
              $(this)
                .text()
                .replace(/(\r\n|\n|\r|\t)/gm, "") +
                " x " +
                $("#duree_sejour").find(":selected").attr("data-duree") +
                " semaines"
            );
          }
          if ($(this).data("semaine") == "0") {
            nomsOptionsDiverses.push(
              $(this)
                .text()
                .replace(/(\r\n|\n|\r|\t)/gm, "")
            );
          }
        });

      // let sommeTarif = tarif.reduce((pv, cv) => parseInt(pv) + parseInt(cv), 0);

      $("input#options_diverses_h").val(tarif);
      $("input#option_options_diverses").val(nomsOptionsDiverses.join("|")).attr('data-detail-options', JSON.stringify(nomsOptionsDiverses));
    });

    /* $(".options_diverses_check").on("change", function () {
      if ($(".options_diverses_check:checked").length != 0) {
        $(".options_diverses_check").attr("disabled", true);
        $(".options_diverses_check:checked").attr("disabled", false);
        const $idDetail = $(".options_diverses_check:checked").data("detail");
        $("div.options_diverses")
          .not(".options_diverses" + $idDetail)
          .hide();
        $(".options_diverses" + $idDetail + " .select2").css(
          "display",
          "inline-block"
        );
      } else {
        $(".options_diverses_check").attr("disabled", false);
        $("div.options_diverses").show();
        $(".options_diverses .select2").css("display", "none");
      }
    }); */

    //cacher detail avant selection
    // $(".options_diverses .select2").css("display", "none");

    //
    //
    //
    // Ajouter tarif assurance annulation :
    $("#choice_2_73_0").click(function () {
      // console.log("je choisi oui");
      $("#assurance_annulation_h").val($(this).val());
      rafraichirPrix();
    });

    $("#choice_2_73_1").click(function () {
      // console.log("je choisi non");
      $("#assurance_annulation_h").val($(this).val());
      rafraichirPrix();
    });
  });
}

if ($("dd.variation-Supplmenthautesaison").length > 0) {
  var str = $("dd.variation-Supplmenthautesaison").html();
  if (str.indexOf('p>0 €</p') != '-1') {
    $(".variation-Supplmenthautesaison").css('display', 'none');
  }
}

if ($(".cart_totals .order-total th").length > 0) {
  $(".cart_totals .order-total th").each(function() {
    var str = $(this).html();
    if (str.indexOf('Paiements futurs') != '-1') {
      $(this).html('Solde à régler plus tard');
    }
  });
}
