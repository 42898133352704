jQuery(document).ready(function ($) {
  $("body.page #content ul, body.single-product .product_content ul:not([class]):not(.gform_fields)").addClass(
    "fa-ul mb-0"
  );
  $("body.page #content ul li:not([class]), body.single-product .product_content ul li:not(.gfield)")
    .addClass("mb-3")
    .prepend(
      '<span class="fa-li"><i class="fas fa-chevron-right text-bleu-light"></i></span>'
    );
});
