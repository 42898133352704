import moment from "moment";

moment.locale("fr");

/* jQuery(document).ready(function ($) {
  //
}); */

export const calculateHauteSaisonPrice = (start, end) => {
  const hasHauteSaison = $("#duree_sejour").data("saison");
  if (hasHauteSaison) {
    const startDate = moment(start);
    const endDate = moment(end);
    const periodesHauteSaison = $("#duree_sejour").data("saisonPeriodes");
    const everyWeek = everyWeekInRange(startDate.add(-1, "days"), endDate);
    //console.log(everyWeek);
    const supplement = [];
    let montantTotalSupplement = 0;
    let nbHauteSemaine = 0;

    const amount = (item) => {
      return item.tarif;
    };

    const sum = (prev, next) => {
      return parseInt(prev) + parseInt(next);
    };

    periodesHauteSaison.map((periode, i) => {
      const startPeriode = moment(periode.date_debut_haute_saison);
      const endPeriode = moment(periode.date_fin_haute_saison);

      everyWeek.map((m) => {
        m = moment(m);
        const start = moment(startPeriode).add(-1, "days");
        //console.log(m.isBetween(start, endPeriode), m.format("LL"));
        if (m.isBetween(start, endPeriode)) {
          supplement.push({
            tarif: periode.tarif_supplement,
            date: m.format("LL"),
          });
          nbHauteSemaine = nbHauteSemaine + 1;
        }
      });
    });

    if (typeof supplement !== "undefined" && supplement.length > 0) {
      montantTotalSupplement = supplement.map(amount).reduce(sum);
    }
    //console.log(montantTotalSupplement, supplement);
    $("#supplement_haute_saison_h").val(montantTotalSupplement);
    $("#nb_haute_saison_h").val(nbHauteSemaine);
  }
};

const everyWeekInRange = (start, end) => {
  //let day = $("#datepicker_start").data("weekstart");

  let result = [];
  let current = start.clone();

  while (current.day(7).isBefore(end)) {
    result.push(current.clone().format());
  }

  return result;
};
