const navTrigger = document.getElementsByClassName("nav-trigger")[0];
const body = document.getElementsByTagName("body")[0];

const closeMenu = document.getElementById("close-menu");
const closeSubMenu = document.getElementById("close-submenu");
const closeMenuSejours = document.getElementById("close-menu-sejours");
const closeMenuVoyages = document.getElementById("close-menu-voyages");

const sidemenuPrincipal = document.getElementById("sidemenu-principal");
const sidemenuSejours = document.getElementById("sidemenu-sejours");
const sidemenuVoyages = document.getElementById("sidemenu-voyages");

navTrigger.addEventListener("click", toggleNavigation);

closeMenu.addEventListener("click", closeNavigation);
closeSubMenu.addEventListener("click", closeNavigationBis);
closeMenuSejours.addEventListener("click", closeNavigation);
closeMenuVoyages.addEventListener("click", closeNavigation);

function closeNavigation(event) {
    event.preventDefault();
    navTrigger.click();
}

function closeNavigationBis(event) {
    event.preventDefault();
    $('.panel-menu-mobile').removeClass('active');
}

function toggleNavigation(event) {
    event.preventDefault();
    body.classList.toggle("nav-open");
    navTrigger.classList.toggle("opened");
    navTrigger.setAttribute(
        "aria-expanded",
        navTrigger.classList.contains("opened")
    );
    sidemenuSejours.classList.add("hidden");
    sidemenuVoyages.classList.add("hidden");
    sidemenuPrincipal.classList.remove("hidden");
    closeMenu.classList.remove("hidden");
    $(".subs").empty();
    $(".subnav").hide();
    $(".sejours .subnav").clone().show().appendTo($(".subs"));
    setMenuPrincipalHeight("principal");
    $('.site-logo').removeClass('menu-open');
}

function toggleNavigationSejours(event) {
    event.preventDefault();
    body.classList.toggle("nav-open");
    navTrigger.classList.toggle("opened");
    navTrigger.setAttribute(
        "aria-expanded",
        navTrigger.classList.contains("opened")
    );
    sidemenuSejours.classList.remove("hidden");
    sidemenuPrincipal.classList.add("hidden");
    sidemenuVoyages.classList.add("hidden");
    closeMenu.classList.add("hidden");
    $(".subs").empty();
    $(".subnav").hide();
    $(".sejours .subnav").clone().show().appendTo($(".subs"));
    setMenuPrincipalHeight("sejours");
    $('.site-logo').toggleClass('menu-open');
}

function toggleNavigationVoyages(event) {
    event.preventDefault();
    body.classList.toggle("nav-open");
    navTrigger.classList.toggle("opened");
    navTrigger.setAttribute(
        "aria-expanded",
        navTrigger.classList.contains("opened")
    );
    sidemenuVoyages.classList.remove("hidden");
    sidemenuPrincipal.classList.add("hidden");
    sidemenuSejours.classList.add("hidden");
    closeMenu.classList.add("hidden");
    $(".subs").empty();
    $(".subnav").hide();
    $(".voyages .subnav").clone().show().appendTo($(".subs"));
    $(".subs .text-bleu-light").css("color", "#FF006C");
    $(".subs  .hover:text-bleu-light").css("fontSize", "50px");
    $(".subs  li:not(.cta) .text-bleu-dark").hover(
        function () {
            $(this).css("color", "#FF006C");
        },
        function () {
            $(this).css("color", "");
        }
    );
    setMenuPrincipalHeight("voyages");
    $('.site-logo').toggleClass('menu-open');
}

// menus secondaires
const menuSejoursBtn = document.getElementById("sejours-menu");
const menuVoyagesBtn = document.getElementById("voyages-menu");
if (typeof menuSejoursBtn != "undefined" && menuSejoursBtn != null) {
    menuSejoursBtn.addEventListener("click", toggleNavigationSejours);
}

if (typeof menuVoyagesBtn != "undefined" && menuVoyagesBtn != null) {
    menuVoyagesBtn.addEventListener("click", toggleNavigationVoyages);
}

//Hauteur du menu principal (pour ne pas cacher les filtres de recherche)
function setMenuPrincipalHeight(type) {
    const heroHeight = $("#hero_block").innerHeight();
    const searchHeight = $("#search-home").innerHeight();
    const headerHeight = $("#masthead").innerHeight();
    const adminHeight = $("#wpadminbar").length ? $("#wpadminbar").height() : 0;
    const $menuPrincipal = $("#navigation.nav-container");
    //let menuHeight = heroHeight - searchHeight + headerHeight + adminHeight;

    let menuHeight = window.height;

    if ($("#wpadminbar").length) {
        $menuPrincipal.css({marginTop: "32px"});
        menuHeight -= 32;
    }

    if (type == "principal") {
        $menuPrincipal.css({height: "", paddingTop: ""});
        $(".subs").css("paddingTop", "");
    }

    if (type == "sejours") {
        $menuPrincipal.css({height: "", paddingTop: ""});
        $(".subs").css("paddingTop", "0");
    }

    /* if (type == "voyages") {
      $menuPrincipal.css({ height: "", paddingTop: "100px" });
      $(".subs").css("paddingTop", "0");
    } */
    // Finalement on affiche la barre de recherche même quand voyages ouvert
    if (type == "voyages") {
        $menuPrincipal.css({height: menuHeight});
        $(".subs").css("paddingTop", "0");
    }
}

// hover menu SL / VS
function hoverNavigationVoyages() {

    menuVoyagesHover.classList.add("active");
    menuSejoursHover.classList.remove("active");
    $(".subs").empty();
    $(".subnav").hide();
    $(".voyages .subnav").clone().fadeIn().appendTo($(".subs"));
    $(".subs .text-bleu-light").css("color", "#FF006C");
    $(".subs  .hover:text-bleu-light").css("fontSize", "50px");
    $(".subs  li:not(.cta) .text-bleu-dark").hover(
        function () {
            $(this).css("color", "#FF006C");
        },
        function () {
            $(this).css("color", "");
        }
    );
}

function hoverNavigationSejours() {

    menuSejoursHover.classList.add("active");
    menuVoyagesHover.classList.remove("active");
    $(".subs").empty();
    $(".subnav").hide();
    $(".sejours .subnav").clone().fadeIn().appendTo($(".subs"));
}

const menuVoyagesHover = document.querySelectorAll(
    "#sidemenu-principal li.voyages"
)[0];
if (typeof menuVoyagesHover != "undefined" && menuVoyagesHover != null) {
    menuVoyagesHover.addEventListener("mouseenter", hoverNavigationVoyages);
}

const menuSejoursHover = document.querySelectorAll(
    "#sidemenu-principal li.sejours"
)[0];
if (typeof menuSejoursHover != "undefined" && menuSejoursHover != null) {
    menuSejoursHover.addEventListener("mouseenter", hoverNavigationSejours);
}

jQuery(document).ready(function ($) {
    if (window.matchMedia("(max-width: 1200px)").matches) {
        $("#sidemenu-principal > ul > li.sejours > a").bind('touchstart click', function () {
            $('.panel-menu-mobile').addClass('active');
            hoverNavigationSejours();
            return false;
        });

        $("#sidemenu-principal > ul > li.voyages > a").bind('touchstart click', function () {
            $('.panel-menu-mobile').addClass('active');
            hoverNavigationVoyages();
            return false;
        });
    }
});