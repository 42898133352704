import "./js/menus";
import "./js/carousels";
import "./js/icon-in-list";
import "./js/animations";
import "./js/modal-produit";
import "./js/modal-voyages";
import "./js/google-maps";
import "./js/ajax-sidebar";
import "./js/ajax-pdf";
import "./js/form";
import "jquery-modal";
import { hoverSlideHome } from "./js/animations";

import "./js/fiche-produit";
import "./js/calendrier-a-la-carte";
import "./js/haute-saison";
import "./js/voyage-scolaire";
import "./js/barre-recherche";
import "./js/recherche";

import select2 from "select2";

jQuery(document).ready(function ($) {

  $('.mail-footer').html('<a href="mailto:eci@eci.asso.fr" class="m-0 text-sm !text-rouge">eci@eci.asso.fr</a>');

  //Filtres séjour de la home (carousel)
  $("#filtre-sejours button.periode").on("click", function () {
    $("#filtre-sejours button.periode").removeClass("active");
    $(this).addClass("active");
    var filter = $(this).data("periode-id");
    var mySwiper = document.querySelector(
      "section.home-sejours .swiper"
    ).swiper;

    $.post(
      ajaxurl,
      {
        action: "filtre-sejours",
        filter: filter,
      },

      function (response) {
        $("section.home-sejours .swiper-wrapper").html(response);
        mySwiper.update();
        mySwiper.slideTo(0);
        hoverSlideHome();
      }
    );

    return false;
  });

  //Smooth scroll Ancres prroduits
  /**
   * Smooth scrolling to page anchor on click
   **/
  /*$("a[href*='#']:not([href='#'])").click(function () {
    if (
      location.hostname == this.hostname &&
      this.pathname.replace(/^\//, "") == location.pathname.replace(/^\//, "")
    ) {
      var anchor = $(this.hash);
      anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) + "]");
      if (anchor.length) {
        let wpadminbarHeight = 0;
        if ($("#wpadminbar").length) {
          wpadminbarHeight = $("#wpadminbar").height();
        }
        let navHeight = 0;
        if ($("#product_nav").length) {
          navHeight = $("#product_nav").height();
        }

        $("html, body").animate(
          { scrollTop: anchor.offset().top - wpadminbarHeight - navHeight },
          1000
        );
      }
    }
  });*/

  // Décalage barre admin pour navigation produit
  let wpadminbarHeight = 0;
  if ($("#wpadminbar").length) {
    wpadminbarHeight = $("#wpadminbar").height();
  }
  $("#product_nav").css("top", wpadminbarHeight);
  // Décalage barre admin pour section Prix  produit
  const sectionPrixPosition = parseInt(
    $(".product section#prix").css("top"),
    10
  );
  $(".product section#prix").css("top", sectionPrixPosition + wpadminbarHeight);

  $('dd.variation-Villededpart').each(function() {
    $(this).parent().parent().parent().find('.product-voyage').html($(this).html());
  });

  

  //
});

function ReLoadImages(){
  jQuery('img[data-lazysrc]').each( function() {
      jQuery( this ).attr( 'src', jQuery( this ).attr( 'data-lazysrc' ) );
  });
}
ReLoadImages();
