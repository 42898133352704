import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

jQuery(document).ready(function ($) {
    // Animation page Nos destinations
    if ($(".nos-destinations article").length) {
        const animDestination = gsap.from(".nos-destinations article", {
            autoAlpha: 0,
            yPercent: 30,
            stagger: 0.04,
        });
        ScrollTrigger.create({
            trigger: ".nos-destinations .grid",
            animation: animDestination,
            once: true,
        });
        //
    }

    // Animation des Titres avec quote

    if ($(".titre-quote").length) {
        const titles = gsap.utils.toArray(".titre-quote");

        titles.forEach((title) => {
            const animTitreQuote = gsap.timeline({
                scrollTrigger: title,
                ease: "elastic.out(1, 0.3)",
            });
            animTitreQuote.fromTo(
                title,
                {autoAlpha: 0, xPercent: -70},
                {autoAlpha: 1, xPercent: 15}
            );
            animTitreQuote.to(title, {xPercent: 0});
        });
    }

    // Animation Logo

    /* const animHeader = gsap.fromTo(
      "header#masthead",
      { autoAlpha: 0, x: -200 },
      { duration: 1, autoAlpha: 1, x: 0 }
    );
    ScrollTrigger.create({
      trigger: "header#masthead",
      animation: animHeader,
      once: true,
    }); */
    //

    // Animation Barre recherche

    // TEMP annulé pour l'instant ça décale l'ancre
    /* if ($("#search-home").length) {
      const animSearch = gsap.fromTo(
        "#search-home",
        { autoAlpha: 0, scaleY: 0, y: 50 },
        { duration: 0.7, autoAlpha: 1, scaleY: 1, y: 0 }
      );
      ScrollTrigger.create({
        trigger: "#search-home",
        animation: animSearch,
        once: true,
      });
    } */

    // Animation titre H1 hero
    if ($(".anim-title").length) {
        gsap.fromTo(
            ".anim-title",
            {autoAlpha: 0, y: -80},
            {duration: 0.7, autoAlpha: 1, y: 0}
        );
    }

    // Anim hover destinations
    if ($(".nos-destinations article").length) {
        $(".nos-destinations article").mouseenter(function () {
            const tl = gsap.timeline();
            tl.to(this, {scale: 1.05, duration: 0.3});
            tl.to(this, {scale: 1, duration: 0.15});
        });
    }

    // Animation hover Produit

    if ($(".archive_content_sidebar article").length || $('#blog_listing article').length) {
        //$(".archive_content_sidebar article, #blog_listing article").each(createHover);

        /*function createHover(i, element) {
          const tl = gsap.timeline({ paused: true, reversed: true });
          /!* tl.from($(element).find("footer"), {
            autoAlpha: 0,
            scale: 0,
            transformOrigin: "bottom",
          }); *!/
          tl.to($(element).find("footer"), {
            autoAlpha: 1,
            scale: 1,
            transformOrigin: "bottom",
            delay: 3 //délai demandé par Flo
          });
          tl.to($(element).find("footer"), {
            opacity: 0.95,
            duration: 0.1,
          });

          $(element).hover(doIt);

          function doIt() {
            tl.reversed() ? tl.play() : tl.reverse();
          }
        }
      }*/

        hoverSlideHome();
    }

    $('#product_nav nav a[href^="#"]').click(function () {
        var the_id = $(this).attr("href");
        if (the_id === '#') {
            return;
        }
        $('html, body').animate({
            scrollTop: $(the_id).offset().top - $('#product_nav').height() - $('#search_form').height() - 120
        }, 'slow');
        return false;
    });
});

// Hover produit sur slide homepage
export function hoverSlideHome() {
    if ($("section.home-sejours article").length) {
        $("section.home-sejours article").each(createHover);

        function createHover(i, element) {
            const tl = gsap.timeline({paused: true, reversed: true});
            /* tl.from($(element).find("footer"), {
            autoAlpha: 0,
            scale: 0,
            transformOrigin: "bottom",
          }); */
            tl.to($(element).find("footer"), {
                autoAlpha: 1,
                scale: 1,
                transformOrigin: "bottom",
                delay: 1.5 //délai demandé par Flo
            });
            tl.to($(element).find("footer"), {
                opacity: 0.95,
                duration: 0.1,
            });

            $(element).hover(doIt);

            function doIt() {
                tl.reversed() ? tl.play() : tl.reverse();
            }
        }
    }
}
