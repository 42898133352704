jQuery(document).ready(function ($) {

  $(".modal-devis-vs").click(function () {
    $("#modal-devis").modal({
      fadeDuration: 250,
      fadeDelay: 0.8,
      showClose: false,
    });
  });
});
