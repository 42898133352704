jQuery(document).ready(function ($) {
    var month_fields = ['input_2_3_1', 'input_2_28_1', 'input_2_29_1'];
    $.each(month_fields, function (index, value) {
        $('#' + value + ' option[value="1"]').html('Janvier');
        $('#' + value + ' option[value="2"]').html('Février');
        $('#' + value + ' option[value="3"]').html('Mars');
        $('#' + value + ' option[value="4"]').html('Avril');
        $('#' + value + ' option[value="5"]').html('Mai');
        $('#' + value + ' option[value="6"]').html('Juin');
        $('#' + value + ' option[value="7"]').html('Juillet');
        $('#' + value + ' option[value="8"]').html('Août');
        $('#' + value + ' option[value="9"]').html('Septembre');
        $('#' + value + ' option[value="10"]').html('Octobre');
        $('#' + value + ' option[value="11"]').html('Novembre');
        $('#' + value + ' option[value="12"]').html('Décembre');
    });

    $(document).on("click", '#field_2_68 label', function () {
        var loc = document.location;
        window.open(loc.protocol + '//' + loc.hostname + '/conditions-generales-de-vente/', '_blank');
    });

    $('.mobile-form-button').click(function () {
        $('#search_form .fields').addClass('active');
        return false;
    });

    $('.close-popin-form').click(function () {
        $('#search_form .fields').removeClass('active');
        return false;
    });

    $('.open-social-mobile').click(function () {
        $('#reseaux_sociaux >.grid').toggleClass('active');
        return false;
    });

    $(document).mouseup(function (e) {
        var container = $('.open-social-mobile');

        // If the target of the click isn't the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('#reseaux_sociaux >.grid').removeClass('active');
        }
    });
});

let h = document.getElementById("search-home"),
productNav = document.getElementById("product_nav"),
stuck = false,
stickPoint = getDistance(),
height = h.offsetHeight;


function getDistance() {
    const topDist = h.offsetTop;
    return topDist;
}

window.onscroll = function (e) {
    const distance = h.offsetTop - window.scrollY, offset = window.scrollY;

    if ((distance <= 0) && !stuck) {
        h.style.position = 'fixed';
        h.style.top = '100px';
        h.style.bottom = 'auto';
        
        if(typeof(productNav) != 'undefined' && productNav != null){
            productNav.style.position = 'fixed';
            productNav.style.top = '180px';
            productNav.style.bottom = 'auto';
            productNav.style.width= "100%";
        }

        stuck = true;
    } else if (stuck && (offset <= stickPoint)) {
        h.style.position = 'absolute';
        h.style.top = 'auto';
        h.style.bottom = 0;

        if(typeof(productNav) != 'undefined' && productNav != null){
            productNav.style.position = 'relative';
            productNav.style.top = 'auto';
            productNav.style.bottom = 0;
        }

        stuck = false;
    }
}